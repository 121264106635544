<script>
import CvPdf from "@/components/CvPdf";

export default {
    name: "CV",
    
    components: {
        CvPdf
    },

    mounted() {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        }, 0);
    },
};
</script>

<template>
    <div>
        <CvPdf />
    </div>
</template>
